import React from "react" // eslint-disable-line no-unused-vars
import { useThemeUI } from "theme-ui"
import PropTypes from "prop-types"
import { RoughNotation } from "react-rough-notation"
import { useNotationGroup } from "./notation-group"

const NotationDeletation = (props) => {
  const { children, id, type, color, ...restProps } = props
  const { state, setNotationChanged } = useNotationGroup()
  const context = useThemeUI()

  const { theme } = context
  const show = state?.[id]?.active

  return (
    <RoughNotation
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (setNotationChanged) {
          setNotationChanged({ id, active: !show })
        }
      }}
      show={show}
      type={type}
      color={theme.colors[color]}
      {...restProps}
    >
      {children}
    </RoughNotation>
  )
}

NotationDeletation.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  color: PropTypes.string,
}

NotationDeletation.defaultProps = {
  type: "strike-through",
  color: "negative",
}

export default NotationDeletation
