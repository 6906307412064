import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import TaskSidebar from "../../../../../components/task-sidebar"
import Note from "../../../../../components/note"
import NotationGroup from "../../../../../components/notation-group"
import SubstantiveAdjektiveNotation from "../../../../../content/kurse/haelfte-des-lebens/01-zwei-haelften/substantive-adjektive-notation"
import Paragraph from "../../../../../components/paragraph"
import NotationDeletation from "../../../../../components/notation-deletation"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      as="form"
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/saetze-ausmisten/loesung" />
      }
    >
      <Seo title="Sätze ausmisten" />
      <Stack>
        <Heading as="h2" level={2}>
          Sätze ausmisten
        </Heading>
        <Paragraph>
          In beiden Strophen hat Hölderlin seine Sätze durch eingeschobene
          Ergänzungen gedehnt und den Satzbau dadurch ziemlich strapaziert. Wie
          würde das Gedicht ohne diese Ergänzungen aussehen? Was würde übrig
          bleiben?
        </Paragraph>
        <TaskSidebar
          main={
            <NotationGroup
              onNotationsUpdated={(notations) => {
                setAnswer({
                  courseId: "haelfte-des-lebens",
                  chapterId: "01-zwei-haelften",
                  taskId: "saetze-ausmisten",
                  answer: notations,
                })
              }}
            >
              <SubstantiveAdjektiveNotation notationType={NotationDeletation} />
            </NotationGroup>
          }
          sidebar={
            <Stack space={6}>
              <Note variant="task">
                Streiche aus den Sätzen alles Überflüssige heraus, indem du die
                Wörter anklickst, die du löschen möchtest. Du kannst die
                Streichung wieder rückgängig machen, indem du noch einmal darauf
                klickst.
              </Note>
            </Stack>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
